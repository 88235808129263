<template>
  <div class="user-picture-container">
    <div class="upload-picture">
      <div class="left">
        <img
          v-if="galleryList.galleryCover"
          :src="$global.imgUrl + galleryList.galleryCover.img"
          @click="setCoverFn"
        />
        <div class="now" v-else @click="setCoverFn">
          <span>+</span>
          <span>设置封面</span>
        </div>
      </div>
      <div class="message">
        <div class="top">
          <p>{{ pictureName }}的图集</p>
          <p>共 {{ getNum }} 张</p>
        </div>
        <div class="bottom">
          <Button @click="addShip">本地上传</Button>
          <span>图片格式为png/jpg，大小不超过10MB</span>
        </div>
      </div>
    </div>
    <ul v-if="galleryList.galleryInfo">
      <li
        class="item"
        v-for="item in galleryList.galleryInfo.list"
        :key="item.id"
      >
        <div class="box">
          <div class="lt">
            <img class="left" :src="$global.imgUrl + item.img" />
            <div class="info">
              <span class="sh" v-if="item.reviewStatus === 0">审核中</span>
              <span class="sh" v-else-if="item.reviewStatus === 1"
                >审核通过</span
              >
              <div v-else style="display: inline-block;">
                <span class="sh fontColor" style="margin-right: 5px;vertical-align: middle;">审核未通过</span>
                <Poptip
                  trigger="hover"
                  placement="right"
                  word-wrap
                >
                  <Icon class="iconBox" type="ios-help"></Icon>
                  <div class="popContent" slot="content" v-text="'未通过-原因：'+item.reason"></div>
                </Poptip>
              </div>
              <p class="tips">描述：{{ item.imgDescribe }}</p>
            </div>
          </div>
          <div class="rt">
            <Button
              class="imgBtn"
              type="text"
              @click="delGallery(item.id)"
              v-throttleClick
            >
              <img src="@/assets/img/userInfo/2222.png" alt="" />
            </Button>
            <Button
              class="imgBtn"
              type="text"
              @click="edit(item)"
              v-throttleClick
            >
              <img src="@/assets/img/userInfo/bi.png" alt="" />
            </Button>
          </div>
        </div>
      </li>
    </ul>
    <Modal title="编辑图片" v-model="showModal">
      <Upload
        :show-upload-list="false"
        :action="actionUrl"
        :on-success="handleSuccess"
        :headers="{ token: token }"
        accept=".png, .jpg, .jpeg"
        ref="uploadFile"
      >
        <Button icon="ios-cloud-upload-outline">选择文件</Button>
      </Upload>
      <img class="cover" :src="$global.imgUrl + value" alt="" v-if="value" />
      <div
        class="now"
        style="
          width: 420px;
          height: 200px;
          margin: 0 auto 30px;
          background-color: #999;
        "
        v-else
      ></div>
      <p style="text-align: left">描述（{{ value1.length }}/100）</p>
      <Input type="textarea" v-model="value1" placeholder="请输入" />
      <div slot="footer">
        <Button @click="showModal = false">取消</Button>
        <Button type="primary" @click="ok" v-throttleClick="2000">确定</Button>
      </div>
    </Modal>
    <Modal title="设置封面" v-model="showModal1">
      <img
        class="cover"
        :src="$global.imgUrl + coverValue"
        alt=""
        v-if="coverValue"
      />
      <div
        class="now"
        style="
          width: 420px;
          height: 200px;
          margin: 0 auto 30px;
          background-color: #999;
        "
        v-else
      ></div>
      <div class="group">
        <div class="change">
          <span class="left" @click="changeTurn('left')">&lt;</span>
          <span class="right" @click="changeTurn('right')">&gt;</span>
        </div>
        <div class="group-list" ref="group">
          <img
            v-for="item in galleryList1"
            :key="item.id"
            :src="$global.imgUrl + item.img"
            alt=""
            @click="enterImg(item)"
          />
        </div>
      </div>
      <div slot="footer">
        <Button @click="cencel">取消</Button>
        <Button type="primary" @click="ok1" v-throttleClick="2000">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "scholarship",
  created() {
    this.gallery();
  },
  computed: {
    ...mapState(["userInfo", "token"]),
    getNum() {
      return (
        this.galleryList.galleryInfo &&
        this.galleryList.galleryInfo.list.filter((item) => {
          return item.reviewStatus === 1;
        }).length
      );
    },
  },
  data() {
    return {
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      showModal: false,
      showModal1: false,
      value: "",
      value1: "",
      galleryList: {},
      galleryList1: [],
      pictureName: "",
      flag: false,
      id: "",
      coverValue: "",
    };
  },
  mounted() {
    this.pictureName = this.userInfo.name;
  },
  methods: {
    cencel() {
      this.showModal1 = false;
      this.coverValue = "";
    },
    enterImg(item) {
      this.galleryId = item.id;
      this.coverValue = item.img;
    },
    changeTurn(item) {
      if (item == "left") {
        this.$refs.group.scrollLeft -= 120;
      } else {
        this.$refs.group.scrollLeft += 120;
      }
    },
    setCoverFn() {
      if (this.getNum === 0) {
        this.$Message.error("只有审核通过才能设置封面");
        return;
      }
      this.gallery1({
        reviewStatus: 1,
      });
      this.showModal1 = true;
    },
    async delGallery(id) {
      let res = await this.$apis.userServe.delGallery({
        galleryId: id,
      });
      if (res.resultCode === "200") {
        this.$Message.success("删除成功");
        this.gallery();
      } else {
        this.$Message.error(res.resultDesc);
      }
    },
    addShip() {
      this.flag = false;
      this.showModal = true;
      this.value = "";
      this.value1 = "";
      this.$refs.uploadFile.clearFiles();
    },
    edit(item) {
      if (item.reviewStatus === 0) {
        this.$Message.error("审核中，无法修改");
        return;
      }
      this.flag = true;
      this.showModal = true;
      this.value = item.img;
      this.value1 = item.imgDescribe;
      this.id = item.id;
    },
    async gallery() {
      let res = await this.$apis.userServe.gallery({
        pageNo: 1,
        pageSize: 10,
      });
      this.galleryList = res.content;
    },
    async gallery1(data) {
      let res = await this.$apis.userServe.gallery(data);
      this.galleryList1 =
        res.content.galleryInfo && res.content.galleryInfo.list;
    },
    handleSuccess(res, file) {
      console.log(res, file);
      this.value = res.content.url;
    },
    ok() {
      if (this.value1.length > 100) {
        this.$Message.error("字数已达上限");
        return;
      }
      if (this.flag) {
        if (this.value == "") {
          this.$Message.error("封面不能为空");
          return;
        }
        if (this.value1 == "") {
          this.$Message.error("描述不能为空");
          return;
        }
        this.modifyGallery({
          img: this.value,
          imgDescribe: this.value1,
          id: this.id,
        });
        this.showModal = false;
        this.value = "";
        this.value1 = "";
      } else {
        if (this.value == "") {
          this.$Message.error("封面不能为空");
          return;
        }
        if (this.value1 == "") {
          this.$Message.error("描述不能为空");
          return;
        }
        this.commitGallery({
          img: this.value,
          imgDescribe: this.value1,
        });
        this.value = "";
        this.value1 = "";
        this.showModal = false;
      }
    },
    async ok1() {
      if (this.coverValue == "") {
        this.$Message.error("封面不能为空");
        return;
      }
      const res = await this.$apis.autherServe.setGalleryCover({
        galleryId: this.galleryId,
      });
      if (res.resultCode === "200") {
        this.$Message.success(res.resultDesc);
        this.gallery();
      } else {
        this.$Message.error(res.resultDesc);
      }
      this.showModal1 = false;
    },
    async commitGallery(data) {
      let res = await this.$apis.userServe.commitGallery(data);
      if (res.data.resultCode === "200") {
        this.$Message.success(res.data.resultDesc);
        this.gallery();
      } else {
        this.$Message.error(res.data.resultDesc);
      }
    },
    async modifyGallery(data) {
      let res = await this.$apis.userServe.modifyGallery(data);
      if (res.data.resultCode === "200") {
        this.$Message.success(res.data.resultDesc);
        this.gallery();
      } else {
        this.$Message.error(res.data.resultDesc);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-poptip-body {
  padding: 5px 10px;
  border-radius: 10px;
}
.popContent {
  max-width: 600px;
  word-break: break-all;
  color: red;
  font-size: 12px;
  text-align: justify;
}
::v-deep .ivu-modal .group {
  width: 420px;
  height: 120px;
  margin: 0px auto;
  background: #fff;
  position: relative;
  .change {
    position: absolute;
    top: 30px;
    font-size: 50px;
    width: 100%;
    user-select: none;
    span {
      position: absolute;
      cursor: pointer;
    }
    .left {
      left: 0;
    }
    .right {
      right: 0;
    }
  }
  .group-list {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
    img {
      width: 160px;
      height: 120px;
      // background: #d3d3d3;
      margin-right: 0;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.left {
  cursor: pointer;
  .now {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 139px;
    height: 139px;
    font-size: 14px;
    color: #00a4ff;
    span:first-child {
      font-size: 50px;
      font-weight: 100;
    }
  }
}
::v-deep .left .ivu-upload {
  width: 139px;
  height: 139px;
  opacity: 0;
  position: absolute;
}
::v-deep .left img {
  width: 100%;
  height: 100%;
}
::v-deep .ivu-modal .ivu-upload {
  opacity: 0;
  position: absolute;
  width: 420px;
  height: 200px;
  left: 50%;
  transform: translateX(-50%);
}
::v-deep .ivu-modal-header,
::v-deep .ivu-modal-footer {
  border: none;
}
::v-deep .ivu-modal-body {
  text-align: center;
  position: relative;
}
::v-deep .ivu-modal-body .cover {
  width: 420px;
  height: 200px;
  margin-bottom: 30px;
}
.user-picture-container {
  ul {
    margin-top: 30px;
    .item {
      height: 159px;
      margin-bottom: 20px;
      &:hover {
        .box {
          background: #f9f9f9;
          .rt {
            display: block;
          }
        }
      }
      .box {
        position: relative;
        margin-left: 11px;
        height: 139px;
        .rt {
          position: absolute;
          top: 55px;
          right: 10px;
          display: none;
          cursor: pointer;
          .imgBtn {
            margin: 0;
            padding: 0 2px;
            border: none;
            background-color: #f9f9f9;
            margin-right: 20px;
            &:focus {
              box-shadow: none !important;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .lt {
        width: 90%;
        display: flex;
        .left {
          width: 139px;
          height: 139px;
          margin-right: 20px;
        }
        .info {
          padding-top: 22px;
          .iconBox {
            width: 16px;
            height: 16px;
            line-height: 16px;
            background: #FB1540;
            border-radius: 8px;
            color: #fff;
          }
          .sh {
            display: inline-block;
            min-width: 50px;
            height: 16px;
            background: #f9f9f9;
            border: 1px dotted #999999;
            border-radius: 4px;
            font-size: 12px;
            color: #999999;
            text-align: center;
            line-height: 16px;
            cursor: pointer;
            padding: 0 5px;
          }
          .tips {
            margin-top: 15px;
            overflow: hidden;
            display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 */
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-all;
            word-wrap:break-word;
            TABLE-LAYOUT:fixed;
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }
  }
  .upload-picture {
    padding-top: 27px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #d6d6d6;
    display: flex;
    .left {
      margin-left: 11px;
      border: 1px solid #999999;
      border-radius: 6px;
      width: 139px;
      height: 139px;
      margin-right: 19px;
    }
    .message {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        p:first-child {
          font-size: 18px;
          color: #333333;
        }
        p:last-child {
          font-size: 14px;
          color: #999999;
        }
      }
      .bottom {
        .ivu-btn {
          width: 100px;
          height: 40px;
          background: #ffffff;
          border: 1px dashed #00a4ff;
          border-radius: 6px;
          font-size: 14px;
          color: #00a4ff;
        }
        span {
          margin-left: 11px;
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}
</style>
